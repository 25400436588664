const app = {
  baseURL: "https://prod.tas.online/",
  // baseURL: "https://prod.tas.online/",
  VUE_APP_SECRET_KEY: "TAS_2024@@",
  VUE_APP_MODE: "production",
};
//https://back.preprod-tas.com/
//https://prod.tas.online/
//https://production.tas-technologies.com/

export default app;
